export const features = [
    {
        title: '✨ Collab with the Best:',
        text: 'Dive into sessions with equally ambitious artists and creators who match your passion. Imagine the magic that can happen when top talent like YOU connects with like-minded creators!'
    },
    {
        title: '💼 Industry-Ready Connections:',
        text: ' Rub shoulders with the insiders—producers, execs, and A&Rs—who are hunting for the next big thing (that’s you, right? 😉).'
    },
    {
        title: '🏠 Inspiring Creative Spaces:',
        text: ' Elevate your creativity in our luxurious Creative Mansions, designed to help you unlock even more greatness!'
    },
    {
        title: '🚀 Showcase Your Brilliance:',
        text: 'Work created at the camp gets showcased to major producers and label A&Rs, offering the perfect launchpad for your next big move.'
    },
]


export const whyJoin = [
    {
        title: '🤝 Safe and Supportive Environment:',
        text: 'Collaborate with writers, producers, and engineers in a pleasant atmosphere that encourages creativity and innovation.'
    },
    {
        title: '🔨 Build Your Skills:',
        text: 'Equip yourself with the tools necessary to excel in your craft and pursue your dreams with confidence.'
    },
    {
        title: '🥇 Engaging Team-Building Activities:', 
        text: 'Participate in challenges and friendly competitions that not only enhance your skills but also build camaraderie among fellow creatives.'
    },
]