import { Grid, Typography } from "@mui/material";
import React, {useContext} from "react";
import HeroSection from '../components/HeroSection';
import ExperienceSection from '../components/ExperienceSection';
import Testimonials from '../components/Testimonials';
import CTASection from '../components/CTASection';
import Footer from '../components/Footer';
import { MainContext } from "../App";

const HomeScreen = () => {
  const {defaultTheme, timeRemaining} = useContext(MainContext)

  return (
    <Grid item container xs={12} style={{color:'white'}}>
      <Grid item container xs={12} style={{ position: 'sticky', top: 0, left: 0, height: 60, zIndex: 2, width: '100%', background: defaultTheme.palette.primary.main, alignItems: 'center', justifyContent: 'center' }}>
                <Typography color='white' variant='h5'>
                  {timeRemaining.days}d : {timeRemaining.hours}h : {timeRemaining.minutes}m : {timeRemaining.seconds}s
                </Typography>
              </Grid>

       <HeroSection />
        <ExperienceSection />
        <Testimonials />
        <CTASection />
        <Footer />
    </Grid>
  );
};

export default HomeScreen;
