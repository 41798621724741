import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Footer = () => {
  return (
    <Container maxWidth="lg" sx={{ textAlign: 'center', py: 4 }}>
      <Typography variant="body2">Creative Content Camps © 2024</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 1 }}>
        {/* Add social media icons/links */}
      </Box>
    </Container>
  );
};

export default Footer;
