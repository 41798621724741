import React, { useContext } from 'react';
import { Container, Typography, Box, Grid, Button } from '@mui/material';
import { motion } from 'framer-motion';
import { whyJoin } from '../Utils';
import { MainContext } from '../App';


const Testimonials = () => {
    const {
        defaultTheme,
        message, setMessage,
        name, setName,
        email, setEmail,
        phone, setPhone,
        instagram, setInstagram,
        selectedDate, setSelectedDate,
        selectedTime, setSelectedTime,
        handleSubmit, setModalOpen,
        error,
    } = useContext(MainContext)
    return (
        <Grid item xs={12} container alignItems="center" justifyContent='center' style={{ background: '#00000090' }}>
            <Container maxWidth="lg" sx={{ py: 14, px:{xs:0, md:8, lg:12} }}>
                <Typography variant="h4" color='secondary' sx={{ textAlign: { xs: 'center', md: 'left' }, mb: 2 }}>📢 What They're Saying:</Typography>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} whileHover={{ scale: .97 }}>
                    <Box
                        component="video"
                        controls
                        src="https://firebasestorage.googleapis.com/v0/b/authenticast.appspot.com/o/creative-content%2FIMG_3110.MOV?alt=media&token=043a92ed-a7a9-469b-8cdc-1931e7979191" // Placeholder video path
                        sx={{ cursor: 'pointer', width: '100%', borderRadius: 2, boxShadow: 3, border: '2px solid white', }}
                    />
                </motion.div>

                <Typography color='primary' variant='h5' sx={{ width: '100%', textAlign: { xs: 'center', md: 'left' }, mt: 2 }}>Why Join Creative Content Camps?</Typography>
                <Typography sx={{ width: '100%', textAlign: { xs: 'center', md: 'left' }, mb: 1 }}>
                    At Creative Content Camps, we don’t just build connections; we create legends. This isn’t a typical camp—it’s a movement for artists ready to rise to the top! 🔥            </Typography>

                <Grid item container justifyContent='center' xs={12} sx={{ margin: { xs: '40px 0px', xl: '100px 0px' } }}>
                    {whyJoin.map((feature, idx) => {
                        return (
                            <Grid item container xs={12} md={6} lg={3} style={{ padding: 14 }}>
                                <motion.div
                                    whileHover={{ background: defaultTheme.palette.secondary.light, scale: .95 }}
                                    style={{ cursor: 'pointer', minHeight: 300, width: '100%', height: 'fit-content', borderRadius: '8px', background: defaultTheme.palette.secondary.main, padding: '10px' }}>
                                    <Typography variant='h4' style={{ fontWeight: 'bold' }}>{feature.title}</Typography>
                                    <Typography style={{ marginTop: 20, color: 'black' }}>{feature.text}</Typography>
                                </motion.div>
                            </Grid>
                        )
                    })}


                    <Button variant='contained' sx={{ my: { xs: 4, md: 8 }, width: { xs: '90%', md: '72.5%' } }}  onClick={() => setModalOpen(true)}> 🔒 Lock Me In!</Button>

                </Grid>
            </Container>
        </Grid>
    );
};

export default Testimonials;
