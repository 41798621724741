import React, { createContext, useReducer, useContext, useState } from "react";
import EmailEditorPanel from "./EmailEditorPanel";
import EmailEditorCanvas from "./EmailEditorCanvas";
import { Grid, Box, Container } from "@mui/material";

// 1. Create Context and Reducer
export let EmailBuilderContext = createContext({});

const EmailMaker = () => {
  const [elements, setElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [subject, setSubject] = useState("");
  const [view, setView] = useState("selector"); //OPTIONS: selector, text, button, image, line

  const addElement = (payload) => {
    setElements((prev) => [...prev, payload]);
  };

  const updateElement = (payload) => {
    setElements((prevElements) =>
      prevElements.map((el) =>
        el.id === payload.id ? { ...el, ...payload } : el
      )
    );

    // Update the selectedElement in context (optional, for UI updates)
    if (selectedElement && selectedElement.id === payload.id) {
      setSelectedElement({ ...selectedElement, ...payload });
    }
  };

  const deleteElement = (payloadId) => {
    setElements(elements.filter((el) => el.id !== payloadId));
  };

  const values = {
    elements,
    setElements,
    selectedElement,
    setSelectedElement,
    addElement,
    deleteElement,
    setElements,
    view,
    setView,
    subject,
    setSubject,
  };

  return (
    <EmailBuilderContext.Provider value={values}>
      <Grid item container xs={12}>
        <Box display="flex" sx={{ width: "100%" }}>
          {/* Editor panel for adding/editing elements */}
          <EmailEditorPanel />
          {/* Canvas area for arranging elements */}
          <EmailEditorCanvas />
        </Box>
      </Grid>
    </EmailBuilderContext.Provider>
  );
};

export default EmailMaker;
