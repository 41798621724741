// EmailEditorPanel.js
import React, { useState, useEffect, useContext } from "react";
import { EmailBuilderContext } from "./EmailMaker";
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  Tooltip,
  Slider,
  Menu,
} from "@mui/material";
import {
  MdOutlineFormatBold,
  MdFormatAlignLeft,
  MdFormatAlignCenter,
  MdFormatAlignRight,
  MdColorLens,
  MdOutlineImage,
  MdOutlineSmartButton,
  MdOutlineMinimize,
  MdKeyboardArrowLeft,
  MdAddLink,
  MdAlignHorizontalCenter,
  MdAlignHorizontalLeft,
  MdAlignHorizontalRight,
  MdOutlineVerticalAlignBottom,
  MdOutlineVerticalAlignTop,
  MdOutlineVerticalAlignCenter,
  MdOutlineFormatColorText,
  MdCropFree,
  MdLineWeight,
  MdLineStyle,
  MdOutlineFormatColorFill,
} from "react-icons/md";
import { motion } from "framer-motion";
import { MainContext } from "../App";

const EmailEditorPanel = () => {
  const {
    elements,
    selectedElement,
    setSelectedElement,
    addElement,
    setElements,
    view,
    setView,
    subject,
    setSubject,
  } = useContext(EmailBuilderContext);
  const { defaultTheme } = useContext(MainContext);

  const [text, setText] = useState(selectedElement?.content || "");
  const [textLink, setTextLink] = useState(selectedElement?.link || "");
  const [fontSize, setFontSize] = useState(selectedElement?.fontSize || 14);
  const [fontColor, setFontColor] = useState(
    selectedElement?.fontColor || "#000000"
  );
  const [fontWeight, setFontWeight] = useState(
    selectedElement?.fontWeight || "normal"
  );
  const [textAlign, setTextAlign] = useState(
    selectedElement?.textAlign || "left"
  );

  const [width, setWidth] = useState(selectedElement?.width || "100%");
  const [lineHeight, setLineHeight] = useState(
    selectedElement?.lineHeight || 2
  );
  const [lineColor, setLineColor] = useState(
    selectedElement?.lineColor || "#000000"
  );

  const [srcLink, setSrcLink] = useState(selectedElement?.src || "");

  //BUTTON STATE
  const [anchorElBR, setAnchorElBR] = useState(null);
  const [anchorElBW, setAnchorElBW] = useState(null);
  const [anchorElBT, setAnchorElBT] = useState(null);
  const [buttonRadius, setButtonRadius] = useState(0);
  const [buttonLineWidth, setButtonLineWidth] = useState(2);
  const [buttonLineType, setButtonLineType] = useState("solid");
  const [buttonColor, setButtonColor] = useState(
    selectedElement?.buttonColor || "#808080"
  );

  const openBR = Boolean(anchorElBR);
  const openBW = Boolean(anchorElBW);
  const openBT = Boolean(anchorElBT);

  const handleClickBR = (event) => {
    setAnchorElBR(event.currentTarget);
  };
  const handleClickBW = (event) => {
    setAnchorElBW(event.currentTarget);
  };
  const handleClickBT = (event) => {
    setAnchorElBT(event.currentTarget);
  };

  const handleCloseBR = () => {
    setAnchorElBR(null);
  };
  const handleCloseBW = () => {
    setAnchorElBW(null);
  };
  const handleCloseBT = () => {
    setAnchorElBT(null);
  };

  const [horizontalAlignment, setHorizontalAlignment] = useState("center");
  const [verticalAlignment, setVerticalAlignment] = useState("center");
  const [padding, setPadding] = useState("0px 0px 0px 0px");
  const [pt, setPt] = useState(0);
  const [pl, setPl] = useState(0);
  const [pb, setPb] = useState(0);
  const [pr, setPr] = useState(0);
  const [margin, setMargin] = useState("0px 0px 0px 0px");
  const [mt, setMt] = useState(0);
  const [ml, setMl] = useState(0);
  const [mb, setMb] = useState(0);
  const [mr, setMr] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState("#fff");

  useEffect(() => {
    if (selectedElement) {
      setText(selectedElement.content);
      setFontSize(selectedElement.fontSize || 14);
      setFontColor(selectedElement.fontColor || "#000000");
      setFontWeight(selectedElement.fontWeight || "normal");
      setTextAlign(selectedElement.textAlign || "left");
      setBackgroundColor(selectedElement.backgroundColor || "#fff");

      setTextLink(selectedElement.link || "");
      setButtonColor(selectedElement.buttonColor || "#808080");
      setLineColor(selectedElement.lineColor || "#000");
      setButtonLineWidth(selectedElement.lineHeight || 2);
      setButtonLineType(selectedElement.border?.split(' ')[1] || 'solid')
      setButtonRadius(selectedElement.buttonRadius || 0)

      setWidth(selectedElement?.width || "100%");
      setHorizontalAlignment(selectedElement?.horizontalAlignment || "center");
      setHorizontalAlignment(selectedElement?.verticalAlignment || "center");
      // setPadding(selectedElement.padding)
      // setMargin(selectedElement.margin)
      // setPt(Number(selectedElement.padding.split('px')[0].trim()))
      // setPl(Number(selectedElement.padding.split('px')[1].trim()))
      // setPb(Number(selectedElement.padding.split('px')[2].trim()))
      // setPr(Number(selectedElement.padding.split('px')[3].trim()))
      // setMt(Number(selectedElement.padding.split('px')[0].trim()))
      // setMl(Number(selectedElement.padding.split('px')[1].trim()))
      // setMb(Number(selectedElement.padding.split('px')[2].trim()))
      // setMr(Number(selectedElement.padding.split('px')[3].trim()))
    }else{
      setPt(0)
      setPl(0)
      setPb(0)
      setPr(0)
      setMt(0)
      setMl(0)
      setMb(0)
      setMr(0)
    }

    console.log("ELEMENT =>", selectedElement);
  }, [selectedElement]);

  //!   ======================== ELEMENT WRAPPER EDIT FUNCTIONS =========================== !//


  const handlePaddingChange = (e, side) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue)) {
      let paddingCopy = padding.slice()
      let paddingSplit = paddingCopy.split(' ')
  
      switch (side) {
        case 'top':
          paddingSplit[0] = `${newValue}px`
          let newPaddingT = paddingSplit.join(' ')
          setPadding(newPaddingT)
          setPt(newValue)
          setSelectedElement({ ...selectedElement, padding: newPaddingT });
      setElements(
        elements.map((el) =>
          el.id === selectedElement.id ? { ...el, padding: newPaddingT } : el
        )
      );
          break;
      
        case 'left':
          paddingSplit[1] = `${newValue}px`
          let newPaddingL = paddingSplit.join(' ')
          setPadding(newPaddingL)
          setPl(newValue)
          setSelectedElement({ ...selectedElement, padding: newPaddingL });
      setElements(
        elements.map((el) =>
          el.id === selectedElement.id ? { ...el, padding: newPaddingL } : el
        )
      );
          break;
      
        case 'bottom':
          paddingSplit[2] = `${newValue}px`
          let newPaddingB = paddingSplit.join(' ')
          setPadding(newPaddingB)
          setPb(newValue)
          setSelectedElement({ ...selectedElement, padding: newPaddingB });
      setElements(
        elements.map((el) =>
          el.id === selectedElement.id ? { ...el, padding: newPaddingB } : el
        )
      );
          break;
      
        case 'right':
          paddingSplit[3] = `${newValue}px`
          let newPaddingR = paddingSplit.join(' ')
          setPadding(newPaddingR)
          setPr(newValue)
          setSelectedElement({ ...selectedElement, padding: newPaddingR });
      setElements(
        elements.map((el) =>
          el.id === selectedElement.id ? { ...el, padding: newPaddingR } : el
        )
      );
          break;
      
        default:
          break;
      }

    }
  };

  const handleMarginChange = (e, side) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue)) {

      let marginCopy = margin.slice()
      let marginSplit = marginCopy.split(' ')
  
      switch (side) {
        case 'top':
          marginSplit[0] = `${newValue}px`
          let newMarginT = marginSplit.join(' ')
          setMargin(newMarginT)
          setMt(newValue)
          setSelectedElement({ ...selectedElement, margin: newMarginT });
      setElements(
        elements.map((el) =>
          el.id === selectedElement.id ? { ...el, margin: newMarginT } : el
        )
      );
          break;
      
        case 'left':
          marginSplit[1] = `${newValue}px`
          let newMarginL = marginSplit.join(' ')
          setMargin(newMarginL)
          setMl(newValue)
          setSelectedElement({ ...selectedElement, margin: newMarginL });
      setElements(
        elements.map((el) =>
          el.id === selectedElement.id ? { ...el, margin: newMarginL } : el
        )
      );
          break;
      
        case 'bottom':
          marginSplit[2] = `${newValue}px`
          let newMarginB = marginSplit.join(' ')
          setMargin(newMarginB)
          setMb(newValue)
          setSelectedElement({ ...selectedElement, margin: newMarginB });
      setElements(
        elements.map((el) =>
          el.id === selectedElement.id ? { ...el, margin: newMarginB } : el
        )
      );
          break;
      
        case 'right':
          marginSplit[3] = `${newValue}px`
          let newPaddingR = marginSplit.join(' ')
          setPadding(newPaddingR)
          setMr(newValue)
          setSelectedElement({ ...selectedElement, margin: newValue });
      setElements(
        elements.map((el) =>
          el.id === selectedElement.id ? { ...el, margin: newValue } : el
        )
      );
          break;
      
        default:
          break;
      }
    }
  };


  const handleWidthChange = (e) => {
    const newWidth = e.target.value;
    setWidth(newWidth);
    setSelectedElement({ ...selectedElement, width: newWidth });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, width: newWidth } : el
      )
    );
  };

  const handleLineHeightChange = (e) => {
    const newLineHeight = e.target.value;
    setLineHeight(newLineHeight);
    setSelectedElement({ ...selectedElement, lineHeight: newLineHeight });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, lineHeight: newLineHeight } : el
      )
    );
  };

  const handleWrapperAttrChange = (e, type) => {
    console.log(e, type);
    const newAttr = e?.target?.value || e;
    switch (type) {
      case "padding":
        setPadding(newAttr);
        setSelectedElement({ ...selectedElement, padding: newAttr });
        setElements(
          elements.map((el) =>
            el.id === selectedElement.id ? { ...el, padding: newAttr } : el
          )
        );
        break;

      case "margin":
        setMargin(newAttr);
        setSelectedElement({ ...selectedElement, margin: newAttr });
        setElements(
          elements.map((el) =>
            el.id === selectedElement.id ? { ...el, margin: newAttr } : el
          )
        );

        break;

      case "horizontalAlignment":
        setHorizontalAlignment(newAttr);
        setSelectedElement({
          ...selectedElement,
          horizontalAlignment: newAttr,
        });
        setElements(
          elements.map((el) =>
            el.id === selectedElement.id
              ? { ...el, horizontalAlignment: newAttr }
              : el
          )
        );
        break;

      case "verticalAlignment":
        setVerticalAlignment(newAttr);
        setSelectedElement({ ...selectedElement, verticalAlignment: newAttr });
        setElements(
          elements.map((el) =>
            el.id === selectedElement.id
              ? { ...el, verticalAlignment: newAttr }
              : el
          )
        );
        break;

      case "backgroundColor":
        setBackgroundColor(newAttr);
        setSelectedElement({ ...selectedElement, backgroundColor: newAttr });
        setElements(
          elements.map((el) =>
            el.id === selectedElement.id
              ? { ...el, backgroundColor: newAttr }
              : el
          )
        );
        break;

      default:
        break;
    }
  };

  const handleBGColorChange = (e) => {
    const newColor = e.target.value;
    setLineColor(newColor);
    setSelectedElement({ ...selectedElement, backgroundColor: newColor });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, backgroundColor: newColor } : el
      )
    );
  };

  //!   ======================== TEXT EDIT FUNCTIONS =========================== !//

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    setSelectedElement({ ...selectedElement, content: newText });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, content: newText } : el
      )
    );
  };

  const handleTextLinkChange = (e) => {
    const newText = e.target.value;
    setTextLink(newText);
    setSelectedElement({ ...selectedElement, link: newText });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, link: newText } : el
      )
    );
  };

  const handleSrcLinkChange = (e) => {
    const newSrc = e.target.value;
    setSrcLink(newSrc);
    setSelectedElement({ ...selectedElement, src: newSrc });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, src: newSrc } : el
      )
    );
  };

  const handleFontSizeChange = (e) => {
    const newFontSize = e.target.value;
    setFontSize(newFontSize);
    setSelectedElement({ ...selectedElement, fontSize: newFontSize });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, fontSize: newFontSize } : el
      )
    );
  };

  const handleFontColorChange = (e) => {
    const newFontColor = e.target.value;
    setFontColor(newFontColor);
    setSelectedElement({ ...selectedElement, fontColor: newFontColor });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, fontColor: newFontColor } : el
      )
    );
  };

  const handleFontWeightChange = () => {
    const newFontWeight = fontWeight === "normal" ? "bold" : "normal";
    setFontWeight(newFontWeight);
    setSelectedElement({ ...selectedElement, fontWeight: newFontWeight });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, fontWeight: newFontWeight } : el
      )
    );
  };

  const handleTextAlignChange = (alignment) => {
    setTextAlign(alignment);
    setSelectedElement({ ...selectedElement, textAlign: alignment });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, textAlign: alignment } : el
      )
    );
  };

  //!   ======================== LINE EDIT FUNCTIONS =========================== !//

  const handleLineColorChange = (e) => {
    const newLineColor = e.target.value;
    setLineColor(newLineColor);
    setSelectedElement({ ...selectedElement, lineColor: newLineColor });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, lineColor: newLineColor } : el
      )
    );
  };

  //!   ======================== BUTTON EDIT FUNCTIONS =========================== !//
  const handeButtonRadiusChange = (e) => {
    const newRadius = e.target.value;
    setButtonRadius(newRadius);
    setSelectedElement({ ...selectedElement, borderRadius: newRadius });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, borderRadius: newRadius } : el
      )
    );
  };

  const handleButtonColorChange = (e) => {
    const newColor = e.target.value;
    setButtonColor(newColor);

    setSelectedElement({ ...selectedElement, buttonColor: newColor });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, buttonColor: newColor } : el
      )
    );
  };

  const handleButtonLineColorChange = (e) => {
    const newLineColor = e.target.value;
    setLineColor(newLineColor);
    let split = { ...selectedElement }?.border.split(" ");
    split[2] = newLineColor;

    setSelectedElement({ ...selectedElement, border: split.join(" ") });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, border: split.join(" ") } : el
      )
    );
  };

  const handleButtonLineTypeChange = (e) => {
    const newLineType = e;
    console.log("line type =>", newLineType);
    setButtonLineType(newLineType);
    let split = { ...selectedElement }?.border.split(" ");
    split[1] = newLineType;

    setSelectedElement({ ...selectedElement, border: split.join(" ") });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, border: split.join(" ") } : el
      )
    );
    handleCloseBT();
  };

  const handleButtonLineWidthChange = (e) => {
    const newLineWidth = e.target.value;
    setButtonLineWidth(newLineWidth);
    let split = { ...selectedElement }?.border.split(" ");
    split[0] = `${newLineWidth}px`;

    setSelectedElement({ ...selectedElement, border: split.join(" ") });
    setElements(
      elements.map((el) =>
        el.id === selectedElement.id ? { ...el, border: split.join(" ") } : el
      )
    );
  };

  // ====================================================================================================
  // ====================================================================================================

  const handleAddElement = (type) => {
    const newElement = {
      id: Date.now(),
      type,
      content:
        type === "text"
          ? ""
          : type === "image"
          ? "New Image"
          : type === "button"
          ? "New Button"
          : type === "line"
          ? "New Line"
          : "New Url",
      fontSize: 14,
      fontColor: "#000000",
      fontWeight: "normal",
      textAlign: "left",
      src: "",
      width: "100%",
      height: "fit-content",
      lineHeight: 2,
      backgroundColor: "#fff",
      lineColor: "#000",
      horizontalAlignment: "center",
      verticalAlignment: "center",
      borderRadius: 0,
      border: "2px solid black",
      buttonColor: "#808080",
      link: "",
      padding:padding,
      margin:margin
    };
    addElement(newElement);
    setView(type);
    setSelectedElement(newElement); // Automatically select the newly added element
  };

  const elementTypes = [
    { text: "Text", icon: <MdFormatAlignCenter style={{ fontSize: 36 }} /> },
    { text: "Image", icon: <MdOutlineImage style={{ fontSize: 36 }} /> },
    { text: "Button", icon: <MdOutlineSmartButton style={{ fontSize: 36 }} /> },
    { text: "Line", icon: <MdOutlineMinimize style={{ fontSize: 36 }} /> },
    { text: "Link", icon: <MdAddLink style={{ fontSize: 36 }} /> },
  ];

  return (
    <Box width="300px" padding={2} border="1px solid #ccc" minHeight="400px" maxHeight='calc(100dvh - 124px)' sx={{overflowY:'scroll'}}>
      <Typography variant="h6" style={{ width: "100%" }}>
        Email Editor
      </Typography>

      <Grid item container xs={12}>
        <Typography variant="caption" sx={{ fontWeight: "bold" }}>
          Subject:
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <Typography
          variant="caption"
          sx={{
            width: "100%",
            textAlign: "right",
            color:
              subject.length === 0
                ? "black"
                : subject.length < 30
                ? "green"
                : subject.length < 40
                ? "orange"
                : "red",
          }}
        >
          {subject.length}/45
        </Typography>
      </Grid>

      {view !== "selector" && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            aria-label=""
            size="small"
            onClick={() => {
              setView("selector");
              setSelectedElement(null);
            }}
          >
            <MdKeyboardArrowLeft />
          </IconButton>
          <Typography>Edit {view}</Typography>
        </div>
      )}

      {/* Add Element Buttons */}
      {view === "selector" && (
        <Grid item container xs={12} marginBottom={2}>
          {elementTypes.map((type) => {
            return (
              <Grid
                item
                container
                xs={6}
                sx={{ padding: 1 }}
                onClick={() => handleAddElement(type.text.toLowerCase())}
              >
                <motion.div
                  style={{
                    borderRadius: 8,
                    border: "1px solid grey",
                    width: "100%",
                  }}
                  whileHover={{
                    cursor: "pointer",
                    color: defaultTheme.palette.primary.main,
                    border: `1px solid ${defaultTheme.palette.primary.main}`,
                    scale: 0.98,
                  }}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    direction="column"
                    style={{
                      padding: "16px 0px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {type.icon}
                    <Typography variant="caption">Add {type.text}</Typography>
                  </Grid>
                </motion.div>
              </Grid>
            );
          })}
        </Grid>
      )}

      {/* ---------------- Text Editing and Formatting Section ------------------------- */}
      {view === "text" && selectedElement?.type === "text" && (
        <Box
          sx={{
            border: "1px solid grey",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          {/* Text Formatting */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ background: "lightgrey", padding: "5px" }}
          >
            <IconButton
              size={"small"}
              onClick={handleFontWeightChange}
              style={{ borderRadius: 8 }}
            >
              <MdOutlineFormatBold
                style={{
                  color: selectedElement?.fontWeight === "bold" && "black",
                }}
              />
            </IconButton>

            {/* Text Alignment */}
            <Box>
              <IconButton
                size={"small"}
                onClick={() => handleTextAlignChange("left")}
                style={{ borderRadius: 8 }}
              >
                <MdFormatAlignLeft
                  style={{
                    color: selectedElement?.textAlign === "left" && "black",
                  }}
                />
              </IconButton>
              <IconButton
                size={"small"}
                onClick={() => handleTextAlignChange("center")}
                style={{ borderRadius: 8 }}
              >
                <MdFormatAlignCenter
                  style={{
                    color: selectedElement?.textAlign === "center" && "black",
                  }}
                />
              </IconButton>
              <IconButton
                size={"small"}
                onClick={() => handleTextAlignChange("right")}
                style={{ borderRadius: 8 }}
              >
                <MdFormatAlignRight
                  style={{
                    color: selectedElement?.textAlign === "right" && "black",
                  }}
                />
              </IconButton>

              {/* Font Color */}
              <Tooltip title={fontColor} placement="top">
                <IconButton
                  size={"small"}
                  onClick={() =>
                    document.getElementById("font-color-picker").click()
                  }
                  style={{ borderRadius: 8 }}
                >
                  <MdOutlineFormatColorText style={{ color: fontColor }} />
                </IconButton>
              </Tooltip>
              <input
                id="font-color-picker"
                type="color"
                value={fontColor}
                onChange={handleFontColorChange}
                style={{ display: "none" }}
              />
            </Box>
          </Box>
          {/* Font Size */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Font Size</InputLabel>
            <Select
              value={fontSize}
              onChange={handleFontSizeChange}
              label="Font Size"
              size="small"
            >
              <MenuItem value={30} style={{ fontSize: 30 }}>
                Heading 1
              </MenuItem>
              <MenuItem value={26} style={{ fontSize: 26 }}>
                Heading 2
              </MenuItem>
              <MenuItem value={24} style={{ fontSize: 24 }}>
                Heading 3
              </MenuItem>
              <MenuItem value={20}>20px</MenuItem>
              <MenuItem value={18}>18px</MenuItem>
              <MenuItem value={16}>16px</MenuItem>
              <MenuItem value={14}>14px</MenuItem>
              <MenuItem value={12}>12px</MenuItem>
              <MenuItem value={10}>10px</MenuItem>
            </Select>
          </FormControl>

          <TextField
            value={text}
            onChange={handleTextChange}
            multiline
            rows={5}
            //maxRows={10}
            variant="outlined"
            fullWidth
            style={{
              fontSize: fontSize,
              fontWeight: fontWeight,
              color: fontColor,
              textAlign: textAlign,
            }}
          />
        </Box>
      )}

      {/* ---------------- Line Editing and Formatting Section ------------------------- */}
      {view === "line" && selectedElement?.type === "line" && (
        <Box
          sx={{
            border: "1px solid grey",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ background: "lightgrey", padding: "5px" }}
          >
            <FormControl margin="normal">
              <InputLabel>Width</InputLabel>
              <Select
                value={width}
                onChange={handleWidthChange}
                label="Width"
                size="small"
              >
                <MenuItem value={20}>20%</MenuItem>
                <MenuItem value={25}>25%</MenuItem>
                <MenuItem value={40}>40%</MenuItem>
                <MenuItem value={50}>50%</MenuItem>
                <MenuItem value={60}>60%</MenuItem>
                <MenuItem value={75}>75%</MenuItem>
                <MenuItem value={80}>80%</MenuItem>
                <MenuItem value={100}>100%</MenuItem>
              </Select>
            </FormControl>

            <FormControl margin="normal">
              <InputLabel>Height</InputLabel>
              <Select
                value={lineHeight}
                onChange={handleLineHeightChange}
                label="Height"
                size="small"
              >
                <MenuItem value={1}>1px</MenuItem>
                <MenuItem value={2}>2px</MenuItem>
                <MenuItem value={3}>3px</MenuItem>
                <MenuItem value={4}>4px</MenuItem>
                <MenuItem value={5}>5px</MenuItem>
                <MenuItem value={6}>6px</MenuItem>
              </Select>
            </FormControl>

            <Tooltip title={lineColor} placement="top">
              <IconButton
                size={"small"}
                onClick={() =>
                  document.getElementById("line-color-picker").click()
                }
                style={{ borderRadius: 8 }}
              >
                <MdColorLens style={{ color: lineColor }} />
              </IconButton>
            </Tooltip>
            <input
              id="line-color-picker"
              type="color"
              value={lineColor}
              onChange={handleLineColorChange}
              style={{ display: "none" }}
            />
          </Box>
        </Box>
      )}

      {/* ---------------- Button Editing and Formatting Section ------------------------- */}
      {view === "button" && selectedElement?.type === "button" && (
        <Box
          sx={{
            border: "1px solid grey",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ background: "lightgrey", padding: "5px" }}
          >
            <IconButton
              size={"small"}
              onClick={handleFontWeightChange}
              style={{ borderRadius: 8 }}
            >
              <MdOutlineFormatBold
                style={{
                  color: selectedElement?.fontWeight === "bold" && "black",
                }}
              />
            </IconButton>

            {/* Text Alignment */}
            <Box>
              <Tooltip
                title={`Border Weight: ${buttonLineWidth}px`}
                placement="top"
              >
                <IconButton
                  size={"small"}
                  id="border-weight-btn"
                  aria-controls={openBW ? "border-weight-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openBW ? "true" : undefined}
                  onClick={handleClickBW}
                  style={{
                    borderRadius: 8,
                    color: buttonLineWidth !== 0 && "#000",
                  }}
                >
                  <MdLineWeight />
                </IconButton>
              </Tooltip>
              <Menu
                id="border-weight-menu"
                aria-labelledby="border-weight-btn"
                anchorEl={anchorElBW}
                open={openBW}
                onClose={handleCloseBW}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{ overflow: "visible" }}
                PaperProps={{
                  style: {
                    overflow: "visible",
                  },
                }}
              >
                <MenuItem sx={{ width: 200, overflow: "visible" }}>
                  <Slider
                    value={buttonLineWidth}
                    onChange={handleButtonLineWidthChange}
                    onMouseUp={handleCloseBW}
                    // getAriaValueText={buttonRadius}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={10}
                  />
                </MenuItem>
              </Menu>

              <Tooltip title={`Border Type: ${buttonLineType}`} placement="top">
                <IconButton
                  size={"small"}
                  id="border-lt-btn"
                  aria-controls={openBT ? "border-lt-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openBT ? "true" : undefined}
                  onClick={handleClickBT}
                  style={{
                    borderRadius: 8,
                    color: buttonLineWidth !== 0 && "#000",
                  }}
                >
                  <MdLineStyle />
                </IconButton>
              </Tooltip>
              <Menu
                id="border-lt-menu"
                aria-labelledby="border-lt-btn"
                value={buttonLineType}
                anchorEl={anchorElBT}
                open={openBT}
                onClose={handleCloseBT}
                // onChange={handleButtonLineTypeChange}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{ color: buttonLineWidth !== 0 && "black" }}
              >
                {["solid", "dashed", "dotted"].map((type, idx) => {
                  return (
                    <MenuItem
                      key={type}
                      sx={{ width: 200 }}
                      value={type}
                      onClick={() => handleButtonLineTypeChange(type)}
                    >
                      {type}
                    </MenuItem>
                  );
                })}
              </Menu>

              <Tooltip title={`Border Color: ${lineColor}`} placement="top">
                <IconButton
                  size={"small"}
                  onClick={() =>
                    document.getElementById("line-color-picker2").click()
                  }
                  style={{ borderRadius: 8 }}
                >
                  <MdColorLens style={{ color: lineColor }} />
                </IconButton>
              </Tooltip>
              <input
                id="line-color-picker2"
                type="color"
                value={lineColor}
                onChange={handleButtonLineColorChange}
                style={{ display: "none" }}
              />

              <Tooltip
                title={`Corner Roundness: ${buttonRadius}px`}
                placement="top"
              >
                <IconButton
                  size={"small"}
                  id="border-radius-btn"
                  aria-controls={openBR ? "border-radius-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openBR ? "true" : undefined}
                  onClick={handleClickBR}
                  style={{
                    borderRadius: 8,
                    color: buttonRadius !== 0 && "#000",
                  }}
                >
                  <MdCropFree />
                </IconButton>
              </Tooltip>
              <Menu
                id="border-radius-menu"
                aria-labelledby="border-radius-btn"
                anchorEl={anchorElBR}
                open={openBR}
                onClose={handleCloseBR}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{ overflow: "visible" }}
                PaperProps={{
                  style: {
                    overflow: "visible",
                  },
                }}
              >
                <MenuItem sx={{ width: 200, overflow: "visible" }}>
                  <Slider
                    value={buttonRadius}
                    onChange={handeButtonRadiusChange}
                    onMouseUp={handleCloseBR}
                    // getAriaValueText={buttonRadius}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={25}
                  />
                </MenuItem>
              </Menu>

              <Tooltip title={fontColor} placement="top">
                <IconButton
                  size={"small"}
                  onClick={() =>
                    document.getElementById("font-color-picker").click()
                  }
                  style={{ borderRadius: 8 }}
                >
                  <MdOutlineFormatColorText style={{ color: fontColor }} />
                </IconButton>
              </Tooltip>
              <input
                id="font-color-picker"
                type="color"
                value={fontColor}
                onChange={handleFontColorChange}
                style={{ display: "none" }}
              />

              <Tooltip title={buttonColor} placement="top">
                <IconButton
                  size={"small"}
                  onClick={() =>
                    document.getElementById("btn-color-picker").click()
                  }
                  style={{ borderRadius: 8 }}
                >
                  <MdColorLens style={{ color: buttonColor }} />
                </IconButton>
              </Tooltip>
              <input
                id="btn-color-picker"
                type="color"
                value={buttonColor}
                onChange={handleButtonColorChange}
                style={{ display: "none" }}
              />
            </Box>
          </Box>

          <Typography
            variant="caption"
            style={{ width: "100%", fontWeight: "bold", marginTop: 10 }}
          >
            Button Text
          </Typography>
          <TextField
            value={text}
            onChange={handleTextChange}
            variant="outlined"
            placeholder="New Button"
            fullWidth
            size="small"
          />
          <Typography
            variant="caption"
            style={{ width: "100%", fontWeight: "bold", marginTop: 10 }}
          >
            Button Link
          </Typography>
          <TextField
            value={textLink}
            onChange={handleTextLinkChange}
            variant="outlined"
            placeholder="https://example.com"
            fullWidth
            size="small"
          />
        </Box>
      )}

      {/* ---------------- Image Editing and Formatting Section ------------------------- */}
      {view === "image" && selectedElement?.type === "image" && (
        <Box
          sx={{
            border: "1px solid grey",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ background: "lightgrey", padding: "5px" }}
          >
            <FormControl margin="normal">
              <InputLabel>Width</InputLabel>
              <Select
                value={width}
                onChange={handleWidthChange}
                label="Width"
                size="small"
              >
                <MenuItem value={10}>10%</MenuItem>
                <MenuItem value={20}>20%</MenuItem>
                <MenuItem value={25}>25%</MenuItem>
                <MenuItem value={40}>40%</MenuItem>
                <MenuItem value={50}>50%</MenuItem>
                <MenuItem value={60}>60%</MenuItem>
                <MenuItem value={75}>75%</MenuItem>
                <MenuItem value={80}>80%</MenuItem>
                <MenuItem value={80}>90%</MenuItem>
                <MenuItem value={100}>100%</MenuItem>
              </Select>
            </FormControl>
          </Box>

          
          <Typography
            variant="caption"
            style={{ width: "100%", fontWeight: "bold", marginTop: 10 }}
          >
            Image Url
          </Typography>
          <TextField
            value={srcLink}
            onChange={handleSrcLinkChange}
            variant="outlined"
            placeholder="https://example.com"
            fullWidth
            size="small"
          />
        </Box>
      )}

      {/* -------------------- WRAPPER EDITOR ------------------------ */}

      {view !== "selector" && (
        <Grid
          item
          container
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ padding: "5px" }}
        >
          <Grid item container xs={6}>
            <Typography variant="caption" style={{ width: "100%" }}>
              Horizontal Align
            </Typography>
            <IconButton
              size={"small"}
              onClick={() =>
                handleWrapperAttrChange("flex-start", "horizontalAlignment")
              }
              style={{
                color:
                  selectedElement?.horizontalAlignment === "flex-start" &&
                  "black",
                borderRadius: 8,
                marginRight: 5,
              }}
            >
              <MdAlignHorizontalLeft />
            </IconButton>
            <IconButton
              size={"small"}
              onClick={() =>
                handleWrapperAttrChange("center", "horizontalAlignment")
              }
              style={{
                color:
                  selectedElement?.horizontalAlignment === "center" && "black",
                borderRadius: 8,
                marginRight: 5,
              }}
            >
              <MdAlignHorizontalCenter />
            </IconButton>
            <IconButton
              size={"small"}
              onClick={() =>
                handleWrapperAttrChange("flex-end", "horizontalAlignment")
              }
              style={{
                color:
                  selectedElement?.horizontalAlignment === "flex-end" &&
                  "black",
                borderRadius: 8,
              }}
            >
              <MdAlignHorizontalRight />
            </IconButton>
          </Grid>

          <Grid item container xs={6}>
            <Typography variant="caption" style={{ width: "100%" }}>
              Vertical Align
            </Typography>
            <IconButton
              size={"small"}
              onClick={() =>
                handleWrapperAttrChange("flex-start", "verticalAlignment")
              }
              style={{
                color:
                  selectedElement?.verticalAlignment === "flex-start" &&
                  "black",
                borderRadius: 8,
                marginRight: 5,
              }}
            >
              <MdOutlineVerticalAlignTop />
            </IconButton>
            <IconButton
              size={"small"}
              onClick={() =>
                handleWrapperAttrChange("center", "verticalAlignment")
              }
              style={{
                color:
                  selectedElement?.verticalAlignment === "center" && "black",
                borderRadius: 8,
                marginRight: 5,
              }}
            >
              <MdOutlineVerticalAlignCenter />
            </IconButton>
            <IconButton
              size={"small"}
              onClick={() =>
                handleWrapperAttrChange("flex-end", "verticalAlignment")
              }
              style={{
                color:
                  selectedElement?.verticalAlignment === "flex-end" && "black",
                borderRadius: 8,
              }}
            >
              <MdOutlineVerticalAlignBottom />
            </IconButton>
          </Grid>

          <Grid item container xs={12} justifyContent='space-between' sx={{mt:1}}>
            <Typography variant="caption" style={{ width: "100%" }}>
              Section Padding
            </Typography>

            <TextField
            //type='number'
            sx={{width:'20%'}}
            size='small'
            value={pt}
            onChange={(e) => {handlePaddingChange(e, 'top')}}
            />
            <TextField
            //type='number'
            sx={{width:'20%'}}
            size='small'
            value={pr}
            onChange={(e) => {handlePaddingChange(e, 'right')}}
            />
            <TextField
            //type='number'
            sx={{width:'20%'}}
            size='small'
            value={pb}
            onChange={(e) => {handlePaddingChange(e, 'bottom')}}
            />
            <TextField
            //type='number'
            sx={{width:'20%'}}
            size='small'
            value={pl}
            onChange={(e) => {handlePaddingChange(e, 'left')}}
            />
          </Grid>

          <Grid item container xs={12} justifyContent='space-between' sx={{mt:1}}>
            <Typography variant="caption" style={{ width: "100%" }}>
              Section Spacing
            </Typography>

            <TextField
            //type='number'
            sx={{width:'20%'}}
            size='small'
            value={mt}
            onChange={(e) => {handleMarginChange(e, 'top')}}
            />
            <TextField
            //type='number'
            sx={{width:'20%'}}
            size='small'
            value={mr}
            onChange={(e) => {handleMarginChange(e, 'right')}}
            />
            <TextField
            //type='number'
            sx={{width:'20%'}}
            size='small'
            value={mb}
            onChange={(e) => {handleMarginChange(e, 'bottom')}}
            />
            <TextField
            //type='number'
            sx={{width:'20%'}}
            size='small'
            value={ml}
            onChange={(e) => {handleMarginChange(e, 'left')}}
            />
          </Grid>

          <Grid item container xs={12} style={{marginTop:10}}>
          <Typography variant="caption" style={{ width: "100%" }}>
              Background Color
            </Typography>
            <Tooltip title={buttonColor} placement="top">
                <IconButton
                  size={"small"}
                  onClick={() =>
                    document.getElementById("bkg-color-picker").click()
                  }
                  style={{ borderRadius: 8 }}
                >
                  <MdColorLens style={{ color: buttonColor }} />
                </IconButton>
              </Tooltip>
              <input
                id="bkg-color-picker"
                type="color"
                value={backgroundColor}
                onChange={handleBGColorChange}
                style={{ display: "none" }}
              />

          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default EmailEditorPanel;
