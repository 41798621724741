import React, { useState, useContext, useEffect } from 'react';
import { Container, Typography, Button, Grid, TextField, Box } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { motion } from 'framer-motion';
import { MainContext } from '../App';
import logo from '../assets/logo1.png'

const HeroSection = () => {
    const { defaultTheme,
        message, setMessage,
        name, setName,
        email, setEmail,
        phone, setPhone,
        instagram, setInstagram,
        selectedDate, setSelectedDate,
        selectedTime, setSelectedTime,
        handleSubmit, setModalOpen,
        error,
    } = useContext(MainContext)


    //   const handleDateChange = (date) => {
    //     setSelectedDate(date);
    //   };



    return (
        <Grid item xs={12} container alignItems="center" justifyContent='center' style={{ minHeight: '100dvh' }}>
            <Container maxWidth="xl" sx={{ py: { xs: 4, md: 8 } }} >
                <Grid container spacing={4} alignItems="center" justifyContent='center' style={{ padding: 20 }}>
                    {/* Video Section */}
                    <Grid container item xs={12}>
                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }} style={{ width: '100%' }}>
                            <Typography variant="h2" sx={{ width: '100%', textAlign: 'center', fontWeight: 'bold', mb: 3, textShadow:'2px 2px 3px black'  }}>Welcome to
                                <img style={{ width: 250, height: 'auto', margin: '0px 20px' }} src={logo} alt='' />
                                Content Camps!</Typography>
                            <Typography variant="h6" sx={{ width: '100%', textAlign: 'center', mb: 1, textShadow:'2px 2px 3px black' }}>
                            🔥🎤 Are you one of the hottest artists, producers, or creators rising in the music world? 🎤🔥
                            </Typography>
                        </motion.div>
                    </Grid>
                    <Grid container item xs={12} md={8}>
                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} whileHover={{ scale: .97 }}>
                            <Box
                                component="video"
                                controls
                                src="https://firebasestorage.googleapis.com/v0/b/authenticast.appspot.com/o/creative-content%2FIMG_4078.MOV?alt=media&token=c07ef4cb-4749-44a0-af0b-0d9630b5810d" // Placeholder video path
                                sx={{ cursor: 'pointer', width: '100%', borderRadius: 2, boxShadow: 3, border: '2px solid white', }}
                            />
                        </motion.div>
                    </Grid>

                    {/* Form Section */}
                    <Grid container item xs={12} md={4} style={{}}>
                        <motion.div initial={{ x: 50, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 1 }} style={{ padding: 20, borderRadius: 8, background: 'white' }}>
                            <Typography color='primary' variant="h5" sx={{ fontWeight: 'bold' }}>Create. Connect. Conquer.</Typography>
                            <Typography color='primary' variant="paragraph2" sx={{margin:'10px 0px'}}>
                                <i>
                                    We're on a mission to find the boldest talent, the fearless creators, and the voices ready to shake up the industry. Sound like you? 🎶
                                </i>
                            </Typography>


                            <form noValidate autoComplete="off">
                                <TextField fullWidth size='small' label="Name" variant="outlined" sx={{ mb: 1 }} onChange={(e) => setName(e.target.value)} value={name} />
                                <TextField fullWidth size='small' label="Phone Number" variant="outlined" sx={{ mb: 1 }} onChange={(e) => setPhone(e.target.value)} value={phone} />
                                <TextField fullWidth size='small' label="Email" variant="outlined" type="email" sx={{ mb: 1 }} onChange={(e) => setEmail(e.target.value)} value={email} />
                                <TextField fullWidth size='small' label="Intagram Link" placeholder="Paste your IG Link Here" variant="outlined" sx={{ mb: 1 }} onChange={(e) => setInstagram(e.target.value)} value={instagram} />

                                {/* Date Picker */}
                                {/* <input
                type="date"
                id="consultation-date"
                name="consultationDate"
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    placeholderText="Select a date"
                    dateFormat="MM/dd/yyyy"
                    style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc', width:'100%', marginBottom:10 }}
                    />

                    <input
                            type="time"
                            id="consultation-time"
                            name="consultationTime"
                            value={selectedTime}
                            onChange={(e) => setSelectedTime(e.target.value)}
                            placeholder="Select a time"
                            style={{
                            padding: '10px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            width: '100%',
                            marginBottom: 10,
                            }}
                        /> */}



                                <Button variant="contained" color="primary" size="large" fullWidth onClick={handleSubmit} >💪 Yes, That's Me!</Button>
                                {error &&
                                    <Typography variant='caption' color='primary' style={{ width: '100%', textAlign: 'center', marginTop: 40 }}>{message}</Typography>
                                }

                            </form>
                        </motion.div>
                    </Grid>


                </Grid>
            </Container>
        </Grid>
    );
};

export default HeroSection;
