import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  IconButton,
  Toolbar,
  AppBar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { FiSend as SendIcon, FiArrowLeft as BackIcon } from "react-icons/fi";
import { MainContext } from "../App";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from "quill"; // Import Quill directly
// import Quill from "quill/dist/quill.js";
import ImageResize from "quill-image-resize";
import "quill-emoji/dist/quill-emoji.css";
import { EmojiBlot, ShortNameEmoji } from "quill-emoji";
import EmailMaker from "../components/EmailMaker";
import firebase, {db} from '../config/firebaseConfig'

// Register plugins
if (typeof Quill !== "undefined") {
    console.log("typeof Quill =>", typeof Quill)
    console.log(Quill)
    // Quill.register("modules/imageResize", ImageResize);
    // Quill.register({
    //   "formats/emoji": EmojiBlot,
    //   "modules/emoji-shortname": ShortNameEmoji,
    // });
  }

const formats = [
  "header", "font", "size", "bold", "italic", "underline", "strike", 
  "color", "background", "list", "bullet", "align", "link", "image", "video", "emoji"
];

// Define modules for rich text editor with image, alignment, and color features
const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    ['link', 'image', 'video'],
    [{ align: [] }],
    ['emoji'],
  ],
  imageResize: {
    modules: ['Resize', 'Display', 'Toolbar'],
  },
  'emoji-toolbar': true,
};

// const contacts = [
//   {
//     id: 1,
//     name: "John Doe",
//     email: "john@example.com",
//     number: "123-456-7890",
//   },
//   {
//     id: 2,
//     name: "Jane Smith",
//     email: "jane@example.com",
//     number: "987-654-3210",
//   },
//   // Add more contacts here...
// ];

const MarketingScreen = ({ userRole }) => {
  const { artists, defaultTheme } = useContext(MainContext);
  const [contacts, setContacts] = useState(null)
  const [emailContent, setEmailContent] = useState("");
  const [textMessage, setTextMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [view, setView] = useState("table"); // 'table' | 'emailForm' | 'textForm'


  useEffect(() => {
    if(!contacts){
      db.collection('creativeCampApplicants').get().then(query => {
        setContacts(query.docs.map(doc => {
          return (
            {...doc.data(), id: doc.id}
          )}
        ))
      })
    }
  }, [contacts])
  

  const handleEditorChange = (content) => {
    setEmailContent(content);
  };

  // Handle email and text message submission
  const sendEmail = () => {
    // Logic to send email via SendGrid API here
  };

  const sendText = () => {
    // Logic to send text via Twilio API here
  };

  // Handle selection of contacts
  const handleSelectContact = (contactId) => {
    setSelectedContacts((prevSelected) =>
      prevSelected.includes(contactId)
        ? prevSelected.filter((id) => id !== contactId)
        : [...prevSelected, contactId]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedContacts(contacts?.map((contact) => contact.id));
    } else {
      setSelectedContacts([]);
    }
  };

  const selectedCount = selectedContacts.length;

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        background: "white",
        padding: 20,
        justifyContent: "center",
        height: "100dvh",
        overflowY: "scroll",
      }}
    >
      <Grid
        item
        container
        xs={12}
        md={11}
        style={{ justifyContent: "center", height: "fit-content" }}
      >
        {view === "table" ? (
          <>
        <Typography
          variant="h4"
          sx={{ width: "100%", textAlign: "center", fontWeight: "bold", my: 1.5 }}
        >
          Marketing
        </Typography>
            {/* Selection Bar */}
            <Grid
              item
              container
              xs={12}
              style={{
                height: "fit-content",
                padding: "0px 10px",
                background: defaultTheme.palette.grey.light,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Typography variant="h6" style={{ flex: 1 }}>
                Selected: {selectedCount}
              </Typography>
              <Button
                // onClick={() => setView("emailForm")}
                onClick={() => {}}
                disabled={selectedCount === 0}
              >
                Email
              </Button>
              <Button
                // onClick={() => setView("textForm")}
                onClick={() => {}}
                disabled={selectedCount === 0}
              >
                Text
              </Button>
            </Grid>

            {/* Contacts Table */}
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          selectedCount > 0 && selectedCount < contacts?.length
                        }
                        checked={selectedCount === contacts?.length}
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contacts?.map((contact) => (
                    <TableRow key={contact.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedContacts.includes(contact.id)}
                          onChange={() => handleSelectContact(contact.id)}
                        />
                      </TableCell>
                      <TableCell>{contact.name}</TableCell>
                      <TableCell>{contact.email}</TableCell>
                      <TableCell>{contact.phone}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            {/* Back Button Bar */}
            <Grid
              item
              container
              xs={12}
              style={{
                height: "fit-content",
                maxHeight:'calc(100dvh - 124px)',
                padding: "0px 10px",
                background: defaultTheme.palette.grey.light,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <IconButton onClick={() => setView("table")}>
                <BackIcon />
              </IconButton>
              <Typography variant="h6" style={{ flex: 1 }}>
                {view === "emailForm"
                  ? "Email Marketing"
                  : "Text Message Marketing"}
              </Typography>
            </Grid>

            {view === "emailForm" ? (
            //   <Grid
            //     item
            //     container
            //     xs={12}
            //     style={{ border: "1px solid lightgrey", padding: 20 }}
            //   >
            //     <Typography variant="caption" sx={{ fontWeight: "bold" }}>
            //       Subject:
            //     </Typography>
            //     <TextField
            //       fullWidth
            //       variant="outlined"
            //       size="small"
            //       type="text"
            //       value={subject}
            //       onChange={(e) => setSubject(e.target.value)}
            //     />
            //     <Typography
            //       variant="caption"
            //       sx={{
            //         width: "100%",
            //         textAlign: "right",
            //         color:
            //           subject.length === 0
            //             ? "black"
            //             : subject.length < 30
            //             ? "green"
            //             : subject.length < 40
            //             ? "orange"
            //             : "red",
            //       }}
            //     >
            //       {subject.length}/45
            //     </Typography>
            //     <Typography variant="caption" sx={{ fontWeight: "bold" }}>
            //       Email:
            //     </Typography>
            //     <ReactQuill style={{ width: "100%", marginBottom: 40, borderRadius: 8, textAlign: "center" }} value={emailContent} onChange={setEmailContent} modules={modules} formats={formats} theme="snow" />
            //   </Grid>
            <EmailMaker/>
            ) : (
              <div>
                <TextField
                  label="Message"
                  multiline
                  maxRows={4}
                  value={textMessage}
                  onChange={(e) => setTextMessage(e.target.value)}
                  inputProps={{ maxLength: 160 }}
                  helperText={`${textMessage.length}/160 characters`}
                  fullWidth
                  style={{ marginBottom: 20 }}
                />
                <Button onClick={sendText} startIcon={<SendIcon />}>
                  Send Text
                </Button>
              </div>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default MarketingScreen;
