import React, {useContext} from 'react';
import { Container, Typography, Button, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import { MainContext } from '../App';

const CTASection = () => {
  const {
    defaultTheme,
    message, setMessage,
    name, setName,
    email, setEmail,
    phone, setPhone,
    instagram, setInstagram,
    selectedDate, setSelectedDate,
    selectedTime, setSelectedTime,
    handleSubmit, setModalOpen,
    error,
} = useContext(MainContext)

  return (
    <Grid item container xs={12} style={{background:'black'}}>
        <Container maxWidth="xl" sx={{ textAlign: 'center', py: 8,  }}>
        <motion.div whileHover={{ scale: 1.05 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>Don’t Miss This Opportunity!</Typography>
            <Button variant="contained" color="secondary" size="large"  onClick={() => setModalOpen(true)}>Sign Up Now</Button>
        </motion.div>
        </Container>
    </Grid>
  );
};

export default CTASection;
