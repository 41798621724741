import React, {useContext} from 'react';
import { Container, Typography, Box, Grid, Button } from '@mui/material';
import { motion } from 'framer-motion';
import {features} from '../Utils'
import { MainContext } from '../App';


const ExperienceSection = () => {
    const {
        defaultTheme,
        message, setMessage, 
        name, setName, 
        email, setEmail, 
        phone, setPhone,
        instagram, setInstagram,
        selectedDate, setSelectedDate,
        selectedTime, setSelectedTime,
        handleSubmit, setModalOpen,
        error,
    } = useContext(MainContext)
  return (
    <Grid item xs={12} container alignItems="center" justifyContent='center' style={{background:'#000000'}}>
        <Container maxWidth="lg" sx={{ py: 14, px:{xs:0, md:8, lg:12} }}>
        <Typography variant="h4" sx={{width:'100%', textAlign:{xs:'center', md:'left'}, mb: 2 }} color='primary'>👀 Here’s What Awaits:</Typography>
        <Typography color='secondary' variant='h5' sx={{width:'100%', textAlign:{xs:'center', md:'left'}, mt:2}}>Collaborative Projects, Industry Connections, and more!</Typography>
        <Typography sx={{width:'100%', textAlign:{xs:'center', md:'left'},  mb: 1}}>Join us for an unforgettable 4-day, 3-night experience tailored for up-and-coming talent—producers, songwriters, artists, and content creators. This is your opportunity to elevate your craft and connect with industry professionals in a vibrant, creative setting.</Typography>

        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} whileHover={{scale:.97}}>
                <Box
                component="video"
                controls
                src="https://firebasestorage.googleapis.com/v0/b/authenticast.appspot.com/o/creative-content%2FIMG_3101.MOV?alt=media&token=e3350710-4db1-4bfe-a0c1-15958807d28d" // Placeholder video path
                sx={{ cursor:'pointer', width: '100%', borderRadius: 2, boxShadow: 3,  border:'2px solid white', }}
                />
            </motion.div>
       
        <Grid item container xs={12}  justifyContent='center' sx={{margin:{xs:'40px 0px', xl:'100px 0px'}}}>
            {features.map((feature, idx) => {
                return (
                    <Grid item container xs={12} md={6} lg={3}  style={{padding:14}}>
                        <motion.div 
                            whileHover={{background:defaultTheme.palette.primary.light, scale:.95}}
                            style={{cursor:'pointer', minHeight:300, width:'100%', height:'fit-content', borderRadius:'8px', background:defaultTheme.palette.primary.main, padding:'10px'}}>
                            <Typography variant='h4' style={{fontWeight:'bold'}}>{feature.title}</Typography>
                            <Typography  style={{marginTop:20, color:'black'}}>{feature.text}</Typography>
                        </motion.div>
                    </Grid>
                )
            })}

        <Button variant='contained' color='secondary' sx={{my:{xs:4, md:8}, width:{xs:'90%', md:'72.5%'}}} onClick={() => setModalOpen(true)} > 👏 I'm Ready!  </Button>


        </Grid>
        
        </Container>
    </Grid>
  );
};

export default ExperienceSection;
