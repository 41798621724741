// EmailEditorCanvas.js
import React, { useContext, useState, userRef } from "react";
import { EmailBuilderContext } from "./EmailMaker";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Button
} from "@mui/material";
import {
  MdEdit,
  MdDelete,
  MdDragIndicator,
  MdMenu,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
} from "react-icons/md";
import { motion } from "framer-motion";
import { MainContext } from "../App";
import defaultImg from '../assets/flower.jpg'

const EmailEditorCanvas = () => {
  const { elements, setSelectedElement, deleteElement, selectedElement, view, setView } =
    useContext(EmailBuilderContext);
  const { defaultTheme } = useContext(MainContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Handlers for element actions
  const handleEdit = (element) => {

    if(element?.id === selectedElement?.id){
        setSelectedElement(null)
    }else{
        setSelectedElement(element);
    }
  };

  const handleDelete = (id) => {
    deleteElement(id);
  };

  const handlePreviewHTML = () => {
    const htmlString = convertToHTML(elements);
    console.log("Generated HTML:", htmlString);
  };

  const convertToHTML = (elements) => {
    return elements
      .map((el) => {
        switch (el.type) {
          case "text":
            return `<p style="
              font-size: ${el.fontSize || "16"}px;
              color: ${el.fontColor || "#000000"};
              font-weight: ${el.fontWeight || "normal"};
              text-align: ${el.textAlign || "left"};
              white-space: pre-wrap;
              ">
              ${el.content || ""}
            </p>`;
          case "image":
            return `<img src="${el.src || 'default-image.jpg'}" 
              alt="${el.alt || "Image"}"
              style="
                width: ${el.width || "100%"};
                height: ${el.height || "auto"};
                padding: 10px;
                opacity: ${el.src ? 1 : 0.5};
              "/>`;
          case "button":
            return `<a href="${el.link || "#"}" 
              style="
                display: inline-block;
                font-size: ${el.fontSize || "16"}px;
                color: ${el.fontColor || "#ffffff"};
                font-weight: ${el.fontWeight || "bold"};
                background-color: ${el.buttonColor || "#808080"};
                text-decoration: none;
                padding: 10px 20px;
                border: ${el.border || "2px solid #000"};
                border-radius: ${el.borderRadius || "0"};
              ">
              ${el.content || "Button"}
            </a>`;
          case "line":
            return `<div style="
              width: ${el.width || "100"}%;
              height: ${el.lineHeight || "2"}px;
              background-color: ${el.lineColor || "#000"};
            "></div>`;
          default:
            return "";
        }
      })
      .join("\n");
  };

  const RenderElement = (el, index) => {
    console.log(el)
    const textStyle = {
      fontSize: `${el.fontSize || "16"}px`,
      color: el.content === "" ? "grey" : el.fontColor || "#000000",
      fontWeight: el.fontWeight,
      textAlign: el.textAlign || "left",
      whiteSpace:'pre-wrap',
      width:'100%'
    };

    const lineStyle = {
        width: `${el.width || "100"}%`,
        height: `${el.lineHeight || "2"}px`,
        backgroundColor:el.lineColor || "#000",
        // border:``,
        // borderTop:``,
    }

    const imgStyle={
      width:`${el.width || "100"}%`,
      height: `${el.height || "auto"}`,
      opacity: el.src ? 1 : .5,
      padding: 10
    }

    const btnStyle ={
        fontSize: `${el.fontSize || "16"}px`,
      color: el.content === "" ? "grey" : el.fontColor || "#000000",
      fontWeight: el.fontWeight,
      backgroundColor: el.buttonColor || '#808080',
      border: el.border || '2px solid #000000',
      borderRadius: el.borderRadius || 0,
      outline: 'none',
      textDecoration: 'none',
      padding: '2px 10px'
    }

    if (!el) return

    return (
      <Grid item container xs={12} key={el.id}>
        <motion.div
          whileHover={{
            cursor: "pointer",
            border:
              selectedElement?.id === el.id
                ? `2px solid ${defaultTheme.palette.primary.light}`
                : "1px solid #ddd",
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border:
              selectedElement?.id === el.id
                ? `2px solid ${defaultTheme.palette.primary.main}`
                : "none",
            // padding: el.type === 'line' && 10,
            position: "relative",
            width:'100%',
          }}
        >
          {/* Icon buttons for editing, deleting, and dragging */}
          <motion.div
            style={{
              border: "1px solid lightgrey",
              background: "white",
              borderRadius: "0px 8px 8px 0px",
              position: "absolute",
              top: "50%",
              right: "-3%",
              transform: "translate(-0%, -50%)",
              display: "flex",
              zIndex: 1,
            }}
          >
            <IconButton
              size="small"
              sx={{ borderRadius: "8px" }}
              onClick={handleMenuClick}
            >
              <MdMenu
                id="demo-positioned-button"
                aria-controls={menuOpen ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
              />
            </IconButton>

            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem style={{ padding: 0 }}>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleCloseMenu();
                  }}
                  sx={{ borderRadius: "8px" }}
                >
                  <MdKeyboardArrowUp />
                </IconButton>
              </MenuItem>
              <MenuItem style={{ padding: 0 }}>
                <IconButton
                  size="small"
                  sx={{ borderRadius: "8px" }}
                  onClick={() => {
                    handleCloseMenu();
                  }}
                >
                  <MdKeyboardArrowDown />
                </IconButton>
              </MenuItem>
              <MenuItem style={{ padding: 0 }}>
                <IconButton
                  size="small"
                  sx={{ borderRadius: "8px" }}
                  onClick={() => {
                    handleCloseMenu();
                    deleteElement(el.id)
                  }}
                >
                  <MdDelete />
                </IconButton>
              </MenuItem>
            </Menu>
          </motion.div>
          {/* Render content based on element type */}
          <Box width="95%" 
          style={{display:'flex', justifyContent: el.horizontalAlignment || 'center', alignItems: el.verticalAlignment || 'center', backgroundColor: el.backgroundColor || '#fff', padding: el.padding || 0, margin: el.margin || 0}}
          onClick={() => {handleEdit(el); setView((el?.type === selectedElement?.type && el?.id === selectedElement?.id) ? 'selector' : el.type )}}>
            {el.type === "text" && (
              <Typography style={textStyle}>
                {el.content || "👈 Add/Edit your text in the Editor Panel.."}
              </Typography>
            )}
            {el.type === "image" && (
              <img
                src={el.src || defaultImg}
                alt="img"
                style={imgStyle}
              />
            )}
            {el.type === "button" && <a href={el.link} target="_blank" style={btnStyle}>{el.content || "Button"}</a>}
            {el.type === "line" && (
              <div style={lineStyle}/>
            )}
          </Box>
        </motion.div>
      </Grid>
    );
  };

  return (
    <Box width="75%" padding={2} border="1px solid #ccc" minHeight="400px" maxHeight='calc(100dvh - 124px)' sx={{position:'relative', overflowX:'hidden', overflowY:'scroll'}}>
      <Typography variant="h6">Email Canvas</Typography>
      <Grid item container xs={12} direction="column" style={{ padding: '10px 10px 80px 10px' }}>
        {elements.map((el, index) => RenderElement(el, index))}
      </Grid>
      <Grid xs={12} style={{zIndex: 1, position:'sticky', bottom: -25, left:'0%', transform:'translate(-0%, -0%)', backgroundColor:'#fff', filter:'blur(6px)', height:70}}/>
      <Button variant='outlined' style={{zIndex:2, position:'sticky', bottom: 0, left:'50%', transform:'translate(-50%, -0%)'}} onClick={handlePreviewHTML}>Preview HTML</Button>
    </Box>
  );
};

export default EmailEditorCanvas;
