import React, { useState, useEffect, createContext } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  setCurrentUser,
  clearCurrentUser,
  setUserData,
  setUserDataSets,
  setUserPresets,
} from "./globalState/authActions";
import { useDispatch, useSelector } from "react-redux";
import { auth, db } from "./config/firebaseConfig";
import { theme as defaultTheme } from "./Theme";
import Toast from "./components/Toast";
import SplashScreen from "./views/SplashScreen";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import HomeScreen from "./views/HomeScreen";
import ContactScreen from "./views/ContactScreen";
import AuthScreen from "./views/LoginScreen";
import background from './assets/background.gif'
import { motion } from 'framer-motion'
// import MobileNavBar from "./components/MobileNavBar";
import { IoIosClose } from "react-icons/io";
import MarketingScreen from "./views/MarketingScreen";


export let MainContext = createContext({});

function App() {
  const targetDate = new Date("December 12, 2024 00:00:00").getTime();

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(true);
  const [info, setInfo] = useState(false);
  const [message, setMessage] = useState("Welcome!");
  const [deviceType, setDeviceType] = useState(null);

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [instagram, setInstagram] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState("");
  const [infoSubbmitted, setInfoSubmitted] = useState(false)
  const [introModalOpen, setIntroModalOpen] = useState(false)


  const [modalOpen, setModalOpen] = useState(false)

  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const handleBookingRedirect = () => {
    window.open('https://calendly.com/djohnohedidnt/30min')
    setModalOpen(false)
    setInfoSubmitted(false)
  };


  const handleSubmit = async () => {
    try {

      if (!name || !email || !phone || !instagram) {
        setMessage(`Please ensure all fields are added!`)
        setError(true)
      } else {
        const user = {
          name,
          email,
          phone,
          linkIG: instagram,
          createdAt: new Date()
        }

        await db.collection('creativeCampApplicants').where('email', '==', email)
          .get()
          .then(async (query) => {
            if (query.docs.length > 0) {
              const existingDoc = query.docs[0];
              const docCreatedAt = existingDoc.data().createdAt.toDate();
              console.log('docCreatedAt => ', docCreatedAt)
              const ninetyDaysBeforeTarget = new Date(targetDate);
              ninetyDaysBeforeTarget.setDate(targetDate.getDate() - 90);

              const oneDayBeforeTarget = new Date(targetDate);
              oneDayBeforeTarget.setDate(targetDate.getDate() - 1);

              if (
                (docCreatedAt >= ninetyDaysBeforeTarget && docCreatedAt <= targetDate) ||
                new Date() > oneDayBeforeTarget
              ) {
                setMessage('You have already applied for this camp, and cannot register again.');
                setError(true);
                return;
              }
            } else {
              await db.collection('creativeCampApplicants').add(user)
              setInfoSubmitted(true)
              setName('')
              setEmail('')
              setPhone('')
              setInstagram('')

              setTimeout(() => { handleBookingRedirect() }, 3000)
            }
          })



      }

    } catch (error) {
      console.log('error => ', error)
      setError(true)
      setMessage('Oops, we can an error processing your request. Please try again.')
    }
  }



  useEffect(() => {
    if (!!error) {
      setTimeout(() => { setError(false); setMessage('') }, 3000)
    }

  }, [error])


  useEffect(() => {

    setTimeout(() => { setIntroModalOpen(true) }, 1500)

    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance <= 0) {
        clearInterval(intervalId);
        setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        setTimeRemaining({
          days: Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((distance % (1000 * 60)) / 1000),
        });
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);


  const values = {
    loading,
    setLoading,
    error,
    setError,
    success,
    setSuccess,
    info,
    setInfo,
    message,
    setMessage,
    defaultTheme,
    name, setName,
    email, setEmail,
    phone, setPhone,
    instagram, setInstagram,
    selectedDate, setSelectedDate,
    selectedTime, setSelectedTime,
    modalOpen, setModalOpen,
    handleSubmit,
    timeRemaining
  };

  useEffect(() => {
    if (!!loading) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
    if (!deviceType) {
      setDeviceType(window.innerWidth < 768 ? "mobile" : "desktop");
    }
  }, []);

  return (
    <MainContext.Provider value={values}>
      <ThemeProvider theme={createTheme(defaultTheme)}>
        <div
          className="App"
          style={{
            height: "100dvh",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundBlendMode: 'hard-light',
            backgroundColor: 'black',
            overflowY: 'scroll',
            overflowX:'hidden'
          }}
        >

          <Toast />
          {loading ? (
            <SplashScreen />
          ) : (
            <Grid item container xs={12} style={{ height: "100%", position: 'relative' }}>
              {/* Other Components Here */}

              {/* <Grid item container xs={12} style={{ position: 'sticky', top: 0, left: 0, height: 60, zIndex: 2, width: '100%', background: defaultTheme.palette.primary.main, alignItems: 'center', justifyContent: 'center' }}>
                <Typography color='white' variant='h5'>
                  {timeRemaining.days}d : {timeRemaining.hours}h : {timeRemaining.minutes}m : {timeRemaining.seconds}s
                </Typography>
              </Grid> */}

              <Grid
                item
                container
                xs={12}
                style={{ height: "calc(100dvh - 60px)", zIndex: 1 }}
              >
                <Routes>
                  <Route path="/" element={<HomeScreen />} />
                 <Route path="/marketing" element={<MarketingScreen />} />
                </Routes>
              </Grid>

              <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Grid container item xs={12} md={7} style={{ position: 'absolute', bottom: 0, left: '50%', transform: 'translate(-50%, 0%)' }}>
                  <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }} style={{ borderRadius: 8, border: '4px solid red' }}>
                    <div style={{ padding: 20, borderRadius: 8, background: 'white', border: '4px solid black' }}>
                      <Typography color='black' variant="h5" sx={{ fontWeight: 'bold' }}>Create. Connect. Conquer.</Typography>

                      <form noValidate autoComplete="off">
                        <TextField fullWidth size='small' label="Name" variant="outlined" sx={{ mb: 1 }} onChange={(e) => setName(e.target.value)} value={name} />
                        <TextField fullWidth size='small' label="Phone Number" variant="outlined" sx={{ mb: 1 }} onChange={(e) => setPhone(e.target.value)} value={phone} />
                        <TextField fullWidth size='small' label="Email" variant="outlined" type="email" sx={{ mb: 1 }} onChange={(e) => setEmail(e.target.value)} value={email} />
                        <TextField fullWidth size='small' label="Intagram Link" placeholder="Paste your IG Link Here" variant="outlined" sx={{ mb: 1 }} onChange={(e) => setInstagram(e.target.value)} value={instagram} />

                        <Button variant="contained" color="primary" size="large" fullWidth onClick={handleSubmit} >Secure Your Spot Today</Button>
                        {error &&
                          <Typography variant='caption' color='primary' style={{ width: '100%', textAlign: 'center', marginTop: 40 }}>{message}</Typography>
                        }

                      </form>
                    </div>
                  </motion.div>
                </Grid>
              </Modal>


              {/* Confirmation Modal */}
              <Modal open={infoSubbmitted} onClose={() => setInfoSubmitted(false)}>
                <Grid container item xs={12} md={7} style={{ position: 'absolute', bottom: 0, left: '50%', transform: 'translate(-50%, 0%)', outline: 'none' }}>
                  <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }} style={{ borderRadius: 8, border: '4px solid red' }}>
                    <div style={{ padding: 20, borderRadius: 8, background: 'white', border: '4px solid black' }}>
                      <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                        🎉 You’re In! 🎉
                      </Typography>
                      <Typography id="modal-description" sx={{ mb: 3, color: '#555' }}>
                        🔥 <strong>We knew you had the spark!</strong> 🔥
                        <br />
                        You’re about to be redirected to secure your spot—hold tight, or tap below if you’re ready to jump ahead!
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBookingRedirect}
                        sx={{
                          mt: 2,

                        }}
                      >
                        Let’s Book It Now! 🚀
                      </Button>
                    </div>

                  </motion.div>
                </Grid>
              </Modal>


              {/* Greg Street Modal */}
              <Modal open={introModalOpen} onClose={() => setIntroModalOpen(false)}>
                <Grid container item xs={12} md={7} style={{ position: 'absolute', bottom: 0, left: '50%', transform: 'translate(-50%, 0%)', outline: 'none' }}>
                  <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }} style={{ borderRadius: 8, border: '4px solid red', position: 'relative' }}>
                    <IconButton 
                    onClick={() => setIntroModalOpen(false)}
                    style={{ position: 'absolute', right: -20, top: -20, border: '2px solid red', background:'white' }}>
                      <IoIosClose />
                    </IconButton>
                    <div style={{ padding: 20, borderRadius: 8, background: 'black', border: '4px solid black' }}>
                      <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2, width: '100%', textAlign: 'center', color: 'white' }}>
                        🔥 Message from Greg Street 🔥
                      </Typography>

                      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} whileHover={{ scale: .97 }}>
                        <Box
                          component="video"
                          controls
                          src="https://firebasestorage.googleapis.com/v0/b/authenticast.appspot.com/o/creative-content%2Fjoined_video_c8f689606a7446be8835bf09ebec4545.mov?alt=media&token=517b41b4-78a4-4f35-b63e-d5c90f72a19f" // Placeholder video path
                          sx={{ cursor: 'pointer', width: '100%', borderRadius: 2, boxShadow: 3, border: '2px solid white', }}
                        />
                      </motion.div>


                    </div>

                  </motion.div>
                </Grid>
              </Modal>

            </Grid>
          )}
        </div>
      </ThemeProvider>
    </MainContext.Provider>
  );
}

export default App;
